.viewWrapper {
    width: 90%;
    height: 100%;
    background-color: #f4f6f8;
    margin-left: 87px;
    margin-right: 87px;
    border-radius: 40px;
    box-shadow: 0px 2px 12px 2px rgba(0, 0, 0, 0.12);
}

.viewTop {
    width: 87%;
    display: flex;
    margin-left: 94px;
}

.viewMiddle{
    width: 87%;
    display: flex;
    margin-top: 40px;
    margin-left: 94px;
}

/* ---------------------------------------------------------- */

.view-doctors--sort-button {
    margin-top: 3em;
    margin-right: 2em;
    padding: 0 2em;
    height: 4em;
    border-radius: 40px;
    background-color: var(--global-blue);
}
.view-doctors--sort-button:hover{
    filter: brightness(55%);
    /* box-shadow: 3px 3px 5px gray; */
    transition-duration: 0.2s;
}
.view-doctors--sort-button--text {
    font-family: var(--global-head);
    font-weight: 400;
    font-size: 26px;
    line-height: 38px;
    color: #fff;
}

/* ---------------------------------------------------------- */

.viewBottom {
    display: flex;

}

.viewLeft {
    flex: 1.5;
}

.viewRight {
    flex: 1;
}

.adBanner {
    margin-top: 60px;
    padding-left: 50px;
    width: 90%;
}

.adImg {
    width: 90%;
    box-shadow: 0px 2px 12px 2px rgba(0, 0, 0, 0.12);
    border-radius: 16px;
}

.view-doctors--doctor-ranking-container{
    width: 80%;
    margin-top: 55px;
    margin-left: 50px;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px 2px rgba(0, 0, 0, 0.12);
    border-radius: 16px;

    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: 3em;
    padding-bottom: 3em;
}

.view-doctors--doctor-ranking-text{
    font-family: var(--global-head);
    font-weight: 700;

    font-size: 36px;
    line-height: 43px;
    color: dimgray;
} 

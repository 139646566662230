/* LEFT -------------------------------------------------------------------------------------------------- */
.register-left--title {
    font-family: var(--global-head);
    font-weight: 700;
    font-size: 5em;
    color: var(--global-blue);
}
/* RIGHT -------------------------------------------------------------------------------------------------- */

.background {
    /* width: 743px;
    height: 743px; */
    background: url(../../../public/assets/system/loginBackground.png);
}


.ranking {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px 2px rgba(0, 0, 0, 0.12);
    border-radius: 16px;
}

.rankRow {
    display: flex;
    margin-top:23px;
}
.rankRow:hover{
    background-color: rgba(220, 220, 220, 0.188);
    border-radius: 20px;
    filter: brightness(55%);
	transition-duration: 0.3s;
    cursor: pointer;
}

.rankPosition {
    margin-top: 25px;
    margin-left: 34px;
    margin-right: 26px;
    font-family: var(--global-head);
    font-weight: 400;
    font-size: 26px;
    line-height: 16px;
    color: #434343;
    opacity: 0.6;
}

.rankAvatar {
    width: 50px;
    height: 50px;
    margin-right: 26px;
    border-radius: 50px;
}

.rankName {
    margin-top: 25px;
    font-family: var(--global-head);
    font-weight: 400;
    font-size: 26px;
    line-height: 16px;
    color: #434343;
    opacity: 0.6;
}

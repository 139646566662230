.slideshow {
    margin: 0 auto;
    overflow: hidden;
    max-width: 500px;
}

.slideshowSlider {
    white-space: nowrap;
    transition: ease 1000ms;
}

.slide {
    display: inline-block;
    height: 200px;
    width: 100%;
    text-align: center;
    border-radius: 40px;
}

.reviewAvatar{
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.slideshowDots {
    text-align: center;
}

.slideshowDot {
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    cursor: pointer;
    margin: 50px 7px 0px;
    background-color: #c4c4c4;
}

.slideshowDot.active {
    background-color: #25b79d;
}

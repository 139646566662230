.profile {
    background: #f5f5f5;
    border-radius: 40px;
    margin-left: 98px;
    margin-right: 98px;
}

/* 1. TOP ---------------------------------------------------------- */

.profileTop {
    display: flex;
    padding-top: 67px;
    padding-left: 37px;
    padding-right: 34px;
}

/* 1.1. TOP LEFT ---------------------------------------------------------- */

.top-left {
    flex: 2;
    text-align: center;
    justify-content: center;
    background: #ffffff;
    border-radius: 40px;
}

.top-left--profile-picture {
    width: 10em;
    height: 10em;
    margin: 3em auto 0em auto;
    border-radius: 40px;
}
.top-left--profile-picture:hover {
    filter: brightness(55%);
	transition-duration: 0.3s;
}

.top-left--profile-name {
    font-family: var(--global-head);
    font-weight: 700;
    font-size: 2em;
    color: var(--global-gray);

    margin: 1em auto 1em auto;
}

.top-left--book-button{
}
.top-left--book-button:hover{
    transition: all 0.3s ease-in-out; 
    transform: scale(1.02);
    filter: brightness(80%);
}
.top-left--book-button:not(:hover) {
    transition: all 0.3s ease-in-out; 
}

.top-left--book-button--text {

    color: #fff;
    font-family: var(--global-head);
    font-weight: 400;
    font-size: 1.2em;

    /* width: 99px;
    height: 26px;
    line-height: 26px;
    color: #ffffff; */

    display: flex;
    flex-direction: row;
    align-items: center;

}

/* 1.2. TOP RIGHT ---------------------------------------------------------- */

.top-right {
    flex: 3;
    display: flex;
    flex-direction: column;
}

.infoTop {
    height: 100%;
    display: flex;
}

.infoCard {
    flex: 1;
    /* height: 245px; */
    background: #ffffff;
    border-radius: 30px;
    margin-left: 12px;
}

.cardTitle {
    font-family: var(--global-head);
    font-weight: 700;
    font-size: 18px;
    margin-left: 24px;
    line-height: 29px;
    color: var(--global-gray)
}


/* .infoBottom {
    flex: 1;
    border-radius: 40px;
    background: #ffffff;
    margin-left: 14px;
    margin-top: 21px;
} */

/* 2. BOTTOM ---------------------------------------------------------- */

.profileBottom {
    display: flex;
    margin-top: 33px;
    padding-bottom: 28px;
    padding-right: 34px;
}

/* 2.1 BOTTOM LEFT ---------------------------------------------------------- */

.bottom-left {
    background: #ffffff;
    border-radius: 30px;
    flex: 2;
    margin-left: 37px;
}

.bottom-left--container {
    display: flex;
    flex-direction: column;
    margin-left: 50px;
}

.bottom-left--container--title {

    font-family: var(--global-head);
    font-weight: 700;
    font-size: 3em;
    color: var(--global-gray);
    margin-top: 1em;
    margin-bottom: 1em;

    /* margin-top: 61px;
    margin-bottom: 38px;
    width: 194px;
    height: 43px;
    line-height: 43px;
    */
}

.bottom-left--container--detail {

    margin-bottom: 1.5em;
    font-size: 1.5em;


    /* margin-bottom: 38px;
    height: 38px;
    font-family: "Acephimere";
    font-weight: 400;
    font-size: 28px;
    line-height: 38px;
    color: #000000; */
}

/* 2.2 BOTTOM RIGHT ---------------------------------------------------------- */

.bottom-right {
    flex: 3;
    background: #ffffff;
    border-radius: 30px;
    margin-left: 23px;
    display: flex;
    flex-direction: column;
}

.bottom-right--container {
    padding: 3em 2em 3em 2em;
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
}

.bottom-right--container--title {
    font-family: var(--global-head);
    font-weight: 700;
    font-size: 3em;
    color: var(--global-gray);

    /* width: 296px;
    height: 43px;
    line-height: 43px; */
}

.bottom-right--container--button--carousel {
    padding-top: 2em;
    flex: 4;
    display: flex;
    flex-direction: column;
}

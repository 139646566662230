body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* TO BE USED GLOBALLY. DO NOT TOUCH. */
/* TO BE USED GLOBALLY. DO NOT TOUCH. */
/* TO BE USED GLOBALLY. DO NOT TOUCH. */

/* SET THE ENTIRE WEBSITE IN THIS BODY FONT */
* {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  /* outline: dashed 1px gainsboro; */
}

/* GLOBAL COLOR VARIABLES */
:root {
  --global-gray: #303030;
  --global-blue: #4054B2;
  --global-green: #23A455;
  --global-head: "Montserrat", sans-serif;
  /* --blue: #00b;
  --fullwidth: 100%; */
}

/* ENABLE SMOTH SCROLLING */
html {
  scroll-behavior: smooth;
}
/* LEFT -------------------------------------------------------------------------------------------------- */
.login-left--title {
    font-family: var(--global-head);
    font-weight: 700;
    font-size: 5em;
    color: var(--global-blue);
}
/* RIGHT -------------------------------------------------------------------------------------------------- */

.hero{
    margin-top: 3em;
    border-radius: 30px;
}

.background {
    /* width: auto;
    height: auto; */
    /* width: 743px;
    height: 743px; */
    background: url(../../../public/assets/system/loginBackground.png);
}

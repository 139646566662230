.share {
    width: 100%;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
    box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
}

.shareWrapper {
    padding: 10px;
}

.shareTop {
    display: flex;
    align-items: center;
}

.shareProfileImg {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}

.shareInput {
    border: none;
    width: 80%;
}

.shareInput:focus {
    outline: none;
}

.shareHr {
    margin: 20px;
}

.shareBottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.shareOptions {
    display: flex;
    margin-left: 20px;
}

.shareOption {
    display: flex;
    align-items: center;
    margin-right: 15px;
    cursor: pointer;
}

.shareIcon {
    font-size: 18px;
    margin-right: 3px;
}

.shareOptionText {
    font-size: 14px;
    font-weight: 500;
}


/* .review-box--button {
    border: none;
    padding: 15px 25px;
    border-radius: 20px;
    background-color: var(--global-green);
    margin-right: 1em;
    cursor: pointer;
    color: white;
}
.review-box--button:hover {
    filter: brightness(55%);
	transition-duration: 0.3s;
} */

.shareImgContainer {
    padding: 0 20px 10px 20px;
    position: relative;
}

.shareImg {
    width: 100%;
    object-fit: cover;
}

.shareCancelImg {
    position: absolute;
    top: 0;
    right: 20px;
    cursor: pointer;
    opacity: 0.7;
}

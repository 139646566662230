.homeWrapper {
    width: 90%;
    height: 100%;
    background-color: #f4f6f8;
    margin-left: 87px;
    margin-right: 87px;
    border-radius: 40px;
    box-shadow: 0px 2px 12px 2px rgba(0, 0, 0, 0.12);
}

.homeTop {
    width: 87%;
    height: 100px;
    padding-top: 30px;
    display: flex;
    margin-left: 94px;
    flex-direction: column;
}

.homeMiddle{
    width: 87%;
    display: flex;
    margin-top: 40px;
    margin-left: 94px;
}

.news-feed--sort-button {
    margin-top: 3em;
    margin-right: 2em;
    width: 12em;
    height: 4em;
    border-radius: 40px;
}
.news-feed--sort-button:hover{
    filter: brightness(55%);
    /* box-shadow: 3px 3px 5px gray; */
    transition-duration: 0.2s;
}
.news-feed--sort-button--text {
    font-family: var(--global-head);
    font-weight: 400;
    font-size: 26px;
    line-height: 38px;
    color: var(--global-gray);
}
.news-feed--sort-button.newest {
    background-color: #e6defc;
}
.news-feed--sort-button.most-liked {
    background-color: #25b79d;
}


.homeBottom {
    display: flex;
    margin-left: 90px;
}

.homeLeft {
    flex: 1.5;
}

.homeRight {
    flex: 1;
}

.adBanner {
    margin-top: 60px;
    padding-left: 50px;
    width: 90%;
}

.adImg {
    width: 90%;
    box-shadow: 0px 2px 12px 2px rgba(0, 0, 0, 0.12);
    border-radius: 16px;
}

.news-feed--doctor-ranking-container {
    width: 80%;
    margin-top: 55px;
    /* margin-left: 50px; */
    background: #FFFFFF;
    box-shadow: 0px 2px 12px 2px rgba(0, 0, 0, 0.12);
    border-radius: 16px;

    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: 3em;
    padding-bottom: 3em;
}

.news-feed--doctor-ranking-text{
    font-family: var(--global-head);
    font-weight: 700;


    font-size: 36px;
    line-height: 43px;
    color: dimgray;
} 

/* ------------- */

.searchbar {
    width: 100%;
    height: 100%;
    background-color: white;
    /* border-radius: 30px; */
    display: flex;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0px 2px 12px 2px rgba(0, 0, 0, 0.12);
}

.searchBarResultDisplay{
   position: absolute;
   margin: auto;
   padding-top: 5px;
   width: 350px;
}

.searchResults{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.result{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    cursor: pointer;
    border-radius: 10px;
    background-color: whitesmoke; 
    box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
}

.resultImg{
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
    padding-left: 10px;
}

.resultUsername{
    padding-left: 10px;
}

.searchInput {
    border: none;
    width: 70%;
}

.searchInput:focus {
    outline: none;
}